<template>
  <div>
    <b-row class="match-height">
      <b-col xl="4" md="4" sm="6">
        <settings-card icon="SettingsIcon" statistic="General Settings" link="general" statistic-title="General Settings" color="secondary" />
      </b-col>
      <b-col xl="4" md="4" sm="6" v-if="$Can('role_access')">
        <settings-card icon="ShieldIcon" statistic="Roles & Permissions" link="userrole" statistic-title="User Roles" color="success" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card icon="UsersIcon" statistic="Customers" link="customer" statistic-title="Settings" color="info" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <settings-card color="warning" icon="BoxIcon" link="inventory" statistic="Inventory" statistic-title="Comments" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <settings-card color="danger" icon="ArrowUpCircleIcon" link="sale" statistic="Sale" statistic-title="Orders" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <settings-card color="primary" icon="ArrowDownCircleIcon" link="purchase" statistic="Purchase" statistic-title="Favorited" />
      </b-col>


      <b-col xl="4" md="4" sm="6">
        <settings-card color="danger" icon="ArrowUpCircleIcon" link="inception" statistic="Inception" statistic-title="Favorited" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <settings-card color="primary" icon="ArrowDownCircleIcon" link="offroad" statistic="Offroad" statistic-title="Favorited" />
      </b-col>


      <b-col xl="4" md="4" sm="6">
        <settings-card color="primary" icon="InboxIcon" link="lead" statistic="Leads" statistic-title="Leads" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="primary" icon="ShoppingBagIcon" link="order" statistic="Orders" statistic-title="Favorited" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <settings-card color="primary" icon="BookOpenIcon" link="accounting" statistic="Accounting" statistic-title="Favorited" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <settings-card color="primary" icon="ArrowDownCircleIcon" link="dropdown" statistic="Dropdown" statistic-title="Dropdown" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="success" icon="FilePlusIcon" link="importExcelsMenu" statistic="Import Excels" statistic-title="ImportExcelsMenu" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="warning" icon="HexagonIcon" link="setRequiredInventory" statistic="Required Inventory Inputs" statistic-title="RequiredInventoryInputs" />
      </b-col>

      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card color="danger" icon="UploadIcon" link="importExcel" statistic="Import Excel" statistic-title="importExcel" />
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination } from 'bootstrap-vue';

import useUserList from './useSettings';

import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from './settingsStoreModule';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,

    SettingsCard,
  },

  methods: {},

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const { loading } = useUserList();

    return {
      loading,
      // Filter
    };
  },
  mounted() {},
};
</script>
